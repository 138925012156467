import React from 'react';
import { motion } from 'framer-motion';
import { Users, Star, Shield, Gift } from 'lucide-react';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const HeroSection = () => {
  return (
    <div className="text-center mb-16">
      {/* Main Heading */}
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-5xl md:text-7xl lg:text-9xl font-black text-white mb-6 tracking-tighter"
        style={{ fontWeight: 950 }}
      >
        {'TRADE WITH'.split('').map((char, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              delay: 0.3 + (index * 0.03),
              duration: 0.4,
              type: "spring",
              stiffness: 300
            }}
            className="inline-block hover:scale-110 transition-transform cursor-default"
          >
            {char === ' ' ? '\u00A0' : char}
          </motion.span>
        ))}
        <br />
        <span className="text-brand-gold">
          {'PAT'.split('').map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                delay: 0.6 + (index * 0.03),
                duration: 0.4,
                type: "spring",
                stiffness: 300
              }}
              className="inline-block hover:scale-110 transition-transform cursor-default"
            >
              {char === ' ' ? '\u00A0' : char}
            </motion.span>
          ))}
        </span>
      </motion.h1>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-gray-400 text-lg max-w-3xl mx-auto mb-12"
      >
        Join my exclusive trading community at HankoX. Experience professional-grade trading with 
        ultra-low spreads, advanced platform features, and 24/7 support.
      </motion.p>

      {/* CTA Buttons */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="flex flex-col sm:flex-row items-center justify-center gap-4"
      >
        <Button
          href={TRADING_URL}
          size="lg"
          icon={Gift}
          className="w-full sm:w-auto min-w-[200px] bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
        >
          START TRADING NOW
        </Button>
        <Button
          href={TRADING_URL}
          variant="secondary"
          size="lg"
          icon={Shield}
          className="w-full sm:w-auto min-w-[200px] bg-dark-light hover:bg-dark border border-brand-gold/20 font-bold tracking-wide"
        >
          OPEN DEMO ACCOUNT
        </Button>
      </motion.div>
    </div>
  );
};

export default HeroSection;