import React from 'react';
import { motion } from 'framer-motion';
import { UserPlus, ArrowRight, Wallet, Download, Star, Shield, Zap, Users, Clock, DollarSign } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';

const accounts = [
  {
    name: 'STP',
    description: 'Get Access to global markets and commission-free',
    minDeposit: '$10',
    features: [
      'Spreads from 0.7 pips',
      'Maximum leverage 1:500',
      'Zero Commissions',
      'USD, EUR, CAD base currencies',
      'Negative Balance Protection',
      'Minimum lot size 0.01',
      'Swap-free Islamic option available'
    ],
    color: 'text-brand-gold',
    gradient: 'from-brand-gold/20 to-brand-gold-light/20',
    borderColor: 'border-brand-gold/20',
    buttonColor: 'bg-brand-gold hover:bg-brand-gold-dark'
  },
  {
    name: 'ECN',
    description: 'Take advantage of tight spreads and competitive commissions',
    minDeposit: '$100',
    features: [
      'Raw spreads from 0.0 pips',
      '$2 per side per $100k traded',
      'Maximum leverage 1:500',
      'USD, EUR, CAD base currencies',
      'Negative Balance Protection',
      'Minimum lot size 0.01',
      'Swap-free Islamic option available'
    ],
    color: 'text-brand-blue',
    gradient: 'from-brand-blue/20 to-brand-blue-light/20',
    borderColor: 'border-brand-blue/20',
    buttonColor: 'bg-brand-blue hover:bg-brand-blue-dark',
    highlight: true
  },
  {
    name: 'ECN Plus',
    description: 'Make the most of your trading with ultra low commissions',
    minDeposit: '$1,000',
    features: [
      'Raw spreads from 0.0 pips',
      '$1 per side per $100k traded',
      'Maximum leverage 1:500',
      'USD, EUR, CAD base currencies',
      'Negative Balance Protection',
      'Minimum lot size 0.01',
      'Swap-free Islamic option available'
    ],
    color: 'text-brand-purple',
    gradient: 'from-brand-purple/20 to-brand-purple-light/20',
    borderColor: 'border-brand-purple/20',
    buttonColor: 'bg-brand-purple hover:bg-brand-purple-dark'
  },
  {
    name: 'Islamic',
    description: 'Swap-Free Trading Account',
    minDeposit: '$10',
    features: [
      'No swap or rollover fees',
      'Shariah-compliant trading',
      'Multiple currency pairs available',
      'Maximum leverage 1:500',
      'All trading platforms accessible',
      'Special admin charges apply',
      '24/5 support'
    ],
    color: 'text-green-400',
    gradient: 'from-green-500/20 to-emerald-500/20',
    borderColor: 'border-green-500/20',
    buttonColor: 'bg-green-600 hover:bg-green-700'
  }
];

const features = [
  { icon: Star, text: 'Ultra-Low Spreads', highlight: 'FROM 0.0' },
  { icon: Shield, text: 'Advanced Platform', highlight: 'HANKOX' },
  { icon: Users, text: '24/7 Support', highlight: 'GLOBAL' }
];

const AccountTypes = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-gold transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20 mb-6"
          >
            <Users className="h-4 w-4 mr-2" />
            Trading Accounts
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-5xl md:text-6xl font-black text-white mb-6 tracking-tighter"
          >
            CHOOSE YOUR{' '}
            <span className="text-brand-gold">TRADING ACCOUNT</span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Select the account type that best suits your trading style and experience level
          </motion.p>
        </div>

        {/* Feature Banners */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-16">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 + index * 0.1 }}
              className="relative group"
            >
              <div className="relative bg-dark-light backdrop-blur-sm p-6 border border-brand-gold/20">
                <div className="flex items-center gap-3 mb-2">
                  <feature.icon className="h-6 w-6 text-brand-gold" />
                  <span className="text-white font-medium">{feature.text}</span>
                </div>
                <div className="text-2xl font-black text-brand-gold tracking-wider">
                  {feature.highlight}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Account Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {accounts.map((account, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 + index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Card Background */}
              <motion.div
                className={`absolute -inset-px rounded-xl bg-gradient-to-r ${account.gradient} blur-xl opacity-50 group-hover:opacity-100`}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 0.7, 0.5],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              {/* Card Content */}
              <div className="relative bg-dark-light backdrop-blur-sm rounded-xl p-8 border border-brand-gold/20 h-full">
                <h3 className={`text-3xl font-black ${account.color} mb-2`}>{account.name}</h3>
                <p className="text-gray-400 mb-6">{account.description}</p>

                <div className="mb-6">
                  <div className="text-3xl font-black text-white mb-2">{account.minDeposit}</div>
                  <p className="text-gray-400">Minimum Deposit</p>
                </div>

                <div className="space-y-4 mb-8">
                  {account.features.map((feature, featureIndex) => (
                    <div key={featureIndex} className="flex items-start gap-3 group">
                      <div className="flex-shrink-0 p-1 bg-brand-gold/10 rounded group-hover:bg-brand-gold/20 transition-colors">
                        <ArrowRight className="h-4 w-4 text-brand-gold" />
                      </div>
                      <span className="text-gray-300 group-hover:text-white transition-colors">
                        {feature}
                      </span>
                    </div>
                  ))}
                </div>

                <Button
                  className={`w-full justify-center text-black font-black tracking-wider ${account.buttonColor}`}
                >
                  OPEN ACCOUNT
                </Button>

                {/* Animated border */}
                <motion.div
                  className="absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-transparent via-brand-gold/50 to-transparent w-full"
                  animate={{
                    scaleX: [0, 1, 0],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                />
              </div>
            </motion.div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AccountTypes;