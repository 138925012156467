import React from 'react';
import { motion } from 'framer-motion';
import { Smartphone, Monitor, Globe, ChevronRight } from 'lucide-react';
import Container from '../../ui/Container';
import Button from '../../ui/Button';

const MobileAppSection = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-gold transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Content Section */}
          <div>
            {/* Banners Container */}
            <div className="flex flex-wrap gap-4 mb-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20"
              >
                <Smartphone className="h-4 w-4 mr-2" />
                Mobile Trading • HankoX
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1 }}
                className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20"
              >
                <Globe className="h-4 w-4 mr-2" />
                Trade Anywhere
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20"
              >
                <Monitor className="h-4 w-4 mr-2" />
                Multi-Platform
              </motion.div>
            </div>

            {/* Updated heading with animated letters */}
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-5xl md:text-6xl font-black text-white mb-6 tracking-tighter"
            >
              EXPERIENCE PROFESSIONAL{' '}
              <span className="text-brand-gold">TRADING ANYWHERE</span>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-gray-400 text-lg mb-8"
            >
              Start trading on the go by downloading HankoX from the App Store or Google Play. Just log in with your existing or new credentials, and don't forget to enter "Hankotrade" in the server tab to select your live server.
            </motion.p>

            {/* Features List */}
            <div className="space-y-6 mb-8">
              {[
                {
                  icon: Globe,
                  title: 'Trade Anywhere',
                  description: 'Access your account and trade from any device',
                  gradient: 'from-green-500/20 to-emerald-500/20'
                },
                {
                  icon: Monitor,
                  title: 'Cross-Platform',
                  description: 'Seamlessly switch between desktop and mobile',
                  gradient: 'from-blue-500/20 to-cyan-500/20'
                },
                {
                  icon: Smartphone,
                  title: 'Mobile-First Design',
                  description: 'Optimized interface for touch screen devices',
                  gradient: 'from-purple-500/20 to-violet-500/20'
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                  className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
                >
                  <div className={`flex-shrink-0 p-2 bg-gradient-to-br ${feature.gradient} rounded-lg`}>
                    <feature.icon className="h-6 w-6 text-brand-gold" />
                  </div>
                  <div>
                    <h3 className="text-white font-semibold mb-1">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* CTA Button */}
            <Button
              size="lg"
              icon={ChevronRight}
              className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              DOWNLOAD NOW
            </Button>
          </div>

          {/* Image Section */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="relative"
          >
            <div className="relative rounded-2xl overflow-hidden shadow-2xl">
              <img 
                src="https://hankotrade.com/static/img/banner-img3.e44d4cd.png"
                alt="HankoX Mobile App"
                className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
              <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold/20 mix-blend-overlay" />
            </div>

            {/* Decorative elements */}
            <div className="absolute -inset-4 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 blur-3xl opacity-50 rounded-full" />
            <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20 backdrop-blur-lg rounded-xl border border-white/10" />
            <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-brand-gold-light/20 to-brand-gold/20 backdrop-blur-lg rounded-xl border border-white/10" />
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default MobileAppSection;