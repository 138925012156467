import React from 'react';
import { motion } from 'framer-motion';
import NumberCounter from '../../animations/NumberCounter';
import { DollarSign, Users, Zap, Clock } from 'lucide-react';

const stats = [
  { 
    icon: DollarSign,
    number: 50,
    suffix: '+',
    label: 'Currency Pairs',
    gradient: 'from-blue-500/20 via-blue-400/20 to-blue-500/20'
  },
  { 
    icon: Users,
    number: 100,
    suffix: 'K+',
    label: 'Active Traders',
    gradient: 'from-purple-500/20 via-purple-400/20 to-purple-500/20'
  },
  { 
    icon: Zap,
    number: 99.9,
    suffix: '%',
    label: 'Execution Rate',
    gradient: 'from-green-500/20 via-green-400/20 to-green-500/20'
  },
  { 
    icon: Clock,
    number: 24,
    suffix: '/7',
    label: 'Support',
    gradient: 'from-orange-500/20 via-orange-400/20 to-orange-500/20'
  }
];

const HeroStats = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 px-4 mb-12">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 + index * 0.1 }}
          className="relative group"
          whileHover={{ y: -5 }}
        >
          {/* Animated gradient background */}
          <motion.div
            className={`absolute -inset-px rounded-xl bg-gradient-to-r ${stat.gradient} blur-xl opacity-50 group-hover:opacity-100`}
            animate={{
              scale: [1, 1.1, 1],
              opacity: [0.5, 0.7, 0.5],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />

          {/* Card content */}
          <div className="relative bg-dark-light backdrop-blur-sm rounded-xl p-6 border border-brand-gold/20 h-full">
            {/* Icon with glow */}
            <div className="relative mb-4">
              <motion.div
                className="absolute -inset-2 rounded-full opacity-0 group-hover:opacity-100 blur-lg transition-opacity duration-300"
                style={{ background: `radial-gradient(circle, ${stat.gradient.split(' ')[0].replace('from-', '')}, transparent 70%)` }}
              />
              <stat.icon className="h-6 w-6 text-brand-gold relative z-10" />
            </div>

            {/* Number with animation */}
            <div className="text-3xl font-black text-white mb-1 flex items-baseline">
              <NumberCounter end={stat.number} suffix={stat.suffix} />
            </div>

            {/* Label */}
            <div className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors">
              {stat.label}
            </div>

            {/* Decorative line */}
            <motion.div
              className="absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-transparent via-brand-gold/50 to-transparent w-full"
              animate={{
                scaleX: [0, 1, 0],
                opacity: [0, 1, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
                delay: index * 0.2
              }}
            />
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default HeroStats;