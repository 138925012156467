import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Server } from 'lucide-react';
import Container from '../ui/Container';

const securityFeatures = [
  {
    icon: Shield,
    title: 'Asset Protection',
    description: 'Your funds are held in segregated accounts at tier-1 banks',
    iconColor: 'text-green-400',
    gradient: 'from-green-500/20 to-emerald-500/20',
    borderGlow: 'group-hover:border-green-500/50'
  },
  {
    icon: Lock,
    title: 'Secure Infrastructure',
    description: 'Military-grade encryption for all transactions and personal data',
    iconColor: 'text-blue-400',
    gradient: 'from-blue-500/20 to-cyan-500/20',
    borderGlow: 'group-hover:border-blue-500/50'
  },
  {
    icon: Server,
    title: 'Reliable Systems',
    description: '99.9% uptime with redundant server infrastructure',
    iconColor: 'text-purple-400',
    gradient: 'from-purple-500/20 to-violet-500/20',
    borderGlow: 'group-hover:border-purple-500/50'
  }
];

const SecuritySection = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-blue transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20 mb-6"
          >
            <Shield className="h-4 w-4 mr-2" />
            Your Security is Our Priority
          </motion.div>

          {/* Updated heading with animated letters */}
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-5xl md:text-7xl lg:text-9xl font-black text-white mb-6 tracking-tighter"
            style={{ fontWeight: 950 }}
          >
            {'TRADE WITH'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ 
                  delay: 0.1 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            <br />
            <motion.span
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5, type: "spring", stiffness: 300 }}
              className="text-brand-gold"
            >
              {'PEACE OF MIND'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ 
                    delay: 0.5 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </motion.span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Your security is our top priority. We employ industry-leading security measures to protect your funds and data.
          </motion.p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {securityFeatures.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 + 0.3 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Animated glow effect */}
              <motion.div
                className={`absolute -inset-px rounded-xl bg-gradient-to-r ${feature.gradient} blur-xl opacity-50 group-hover:opacity-100`}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 0.7, 0.5],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              <div className={`relative bg-dark-light backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 ${feature.borderGlow} h-full`}>
                {/* Animated icon */}
                <motion.div
                  className={`flex items-center justify-center w-14 h-14 rounded-lg bg-gradient-to-br ${feature.gradient}`}
                  animate={{
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, 0],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                >
                  <feature.icon className={`h-7 w-7 ${feature.iconColor}`} />
                </motion.div>

                <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-blue-400 transition-colors">
                  {feature.title}
                </h3>

                <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                  {feature.description}
                </p>

                {/* Animated line effect */}
                <motion.div
                  className={`absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-transparent ${feature.gradient} to-transparent w-full`}
                  animate={{
                    scaleX: [0, 1, 0],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                />
              </div>
            </motion.div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default SecuritySection;