import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { Users, Star, Shield, Gift, TrendingUp } from 'lucide-react';
import SectionBanner from '../components/sections/SectionBanner';
import SponsorHero from '../components/sponsors/SponsorHero';
import InfluencerGrid from '../components/sponsors/InfluencerGrid';
import BenefitsSection from '../components/sponsors/BenefitsSection';
import ApplicationProcess from '../components/sponsors/ApplicationProcess';
import CommissionStructure from '../components/sponsors/CommissionStructure';
import SponsorCTA from '../components/sponsors/SponsorCTA';

const Sponsors = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="BECOME A" 
        highlightText="PARTNER" 
        ctaText="JOIN NOW"
        ctaLink="#hero"
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Star className="h-4 w-4 mr-2" />
              INFLUENCER PROGRAM
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <TrendingUp className="h-4 w-4 mr-2" />
              HIGH COMMISSIONS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              VERIFIED PARTNERS
            </motion.div>
          </div>

          <SponsorHero />
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* Featured Partners Section */}
        <SectionBanner 
          text="FEATURED" 
          highlightText="PARTNERS" 
          ctaText="MEET PARTNERS"
          ctaLink="#partners"
        />

        <div id="partners">
          <Container className="py-24">
            <InfluencerGrid />
          </Container>
        </div>

        {/* Benefits Section */}
        <SectionBanner 
          text="PARTNER" 
          highlightText="BENEFITS" 
          ctaText="LEARN MORE"
          ctaLink="#benefits"
        />

        <div id="benefits">
          <Container className="py-24">
            <BenefitsSection />
          </Container>
        </div>

        {/* Commission Structure Section */}
        <SectionBanner 
          text="COMMISSION" 
          highlightText="STRUCTURE" 
          ctaText="VIEW RATES"
          ctaLink="#commissions"
        />

        <div id="commissions">
          <Container className="py-24">
            <CommissionStructure />
          </Container>
        </div>

        {/* Application Process Section */}
        <SectionBanner 
          text="APPLICATION" 
          highlightText="PROCESS" 
          ctaText="GET STARTED"
          ctaLink="#process"
        />

        <div id="process">
          <Container className="py-24">
            <ApplicationProcess />
          </Container>
        </div>

        {/* CTA Section */}
        <Container className="py-24">
          <SponsorCTA />
        </Container>

        {/* Final Banner */}
        <SectionBanner 
          text="JOIN OUR" 
          highlightText="NETWORK" 
          ctaText="APPLY NOW"
          ctaLink="#apply"
          className="mb-0"
        />
      </div>
    </div>
  );
};

export default Sponsors;