import React from 'react';
import { motion } from 'framer-motion';
import { DivideIcon as LucideIcon } from 'lucide-react';
import Button from '../../ui/Button';

interface StepCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  buttonText: string;
  href: string;
  index: number;
}

const StepCard: React.FC<StepCardProps> = ({
  icon: Icon,
  title,
  description,
  buttonText,
  href,
  index
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.2 }}
      className="relative group"
    >
      <div className="absolute -inset-px rounded-lg bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 blur opacity-75 group-hover:opacity-100 transition-opacity" />
      
      <div className="relative bg-dark-light backdrop-blur-sm rounded-lg p-8 border border-brand-gold/20 group-hover:border-brand-gold/50">
        <div className="flex items-center mb-6">
          <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20">
            <Icon className="h-6 w-6 text-brand-gold" />
          </div>
          <div className="ml-4 text-2xl font-bold text-white">
            Step {index + 1}
          </div>
        </div>
        
        <h3 className="text-xl font-semibold text-white mb-2">
          {title}
        </h3>
        
        <p className="text-gray-400 mb-6">
          {description}
        </p>

        <Button
          href={href}
          className="w-full justify-center bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
        >
          {buttonText}
        </Button>
      </div>
    </motion.div>
  );
};

export default StepCard;