import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Bitcoin, Coins, LineChart, BarChart2, TrendingUp } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';

const markets = [
  {
    icon: DollarSign,
    name: 'Forex',
    title: '50+ Currency Pairs',
    description: 'Trade major, minor, and exotic currency pairs with competitive spreads',
    color: 'text-green-400',
    gradient: 'from-green-500/20 to-emerald-500/20'
  },
  {
    icon: Bitcoin,
    name: 'Crypto',
    title: '20+ Cryptocurrencies',
    description: 'Access the most popular cryptocurrencies with 24/7 trading',
    color: 'text-orange-400',
    gradient: 'from-orange-500/20 to-red-500/20'
  },
  {
    icon: Coins,
    name: 'Commodities',
    title: 'Gold, Silver & More',
    description: 'Trade precious metals and energy products with low spreads',
    color: 'text-yellow-400',
    gradient: 'from-yellow-500/20 to-amber-500/20'
  },
  {
    icon: LineChart,
    name: 'Indices',
    title: 'Global Indices',
    description: 'Trade major stock indices from around the world',
    color: 'text-blue-400',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  }
];

const Markets = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-gold transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20 mb-6"
          >
            <TrendingUp className="h-4 w-4 mr-2" />
            Multiple Markets • One Platform
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-5xl md:text-6xl font-black text-white mb-6 tracking-tighter"
          >
            DIVERSE{' '}
            <span className="text-brand-gold">TRADING MARKETS</span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Trade across multiple markets with competitive spreads and advanced trading tools
          </motion.p>
        </div>

        {/* Market Icons Grid */}
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4 mb-16">
          {[
            { icon: DollarSign, label: 'Forex', color: 'text-green-400' },
            { icon: Bitcoin, label: 'Crypto', color: 'text-orange-400' },
            { icon: Coins, label: 'Commodities', color: 'text-yellow-400' },
            { icon: BarChart2, label: 'Indices', color: 'text-blue-400' },
            { icon: LineChart, label: 'Stocks', color: 'text-purple-400' },
            { icon: TrendingUp, label: 'Metals', color: 'text-red-400' }
          ].map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 + index * 0.1 }}
              className="group relative"
            >
              <div className="relative bg-dark-light backdrop-blur-sm rounded-xl p-4 border border-brand-gold/20 text-center">
                <item.icon className={`h-8 w-8 mx-auto mb-2 ${item.color}`} />
                <div className="text-sm text-gray-400 group-hover:text-white transition-colors">
                  {item.label}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Market Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {markets.map((market, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 + index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Card Background */}
              <motion.div
                className={`absolute -inset-px rounded-xl bg-gradient-to-r ${market.gradient} blur-xl opacity-50 group-hover:opacity-100`}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 0.7, 0.5],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              {/* Card Content */}
              <div className="relative bg-dark-light backdrop-blur-sm rounded-xl p-8 border border-brand-gold/20 h-full">
                <market.icon className={`h-10 w-10 ${market.color} mb-6`} />
                <h3 className="text-xl font-bold text-white mb-2">{market.title}</h3>
                <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                  {market.description}
                </p>

                {/* Animated border */}
                <motion.div
                  className="absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-transparent via-brand-gold/50 to-transparent w-full"
                  animate={{
                    scaleX: [0, 1, 0],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                />
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.8 }}
          className="text-center mt-12"
        >
          <Button
            size="lg"
            icon={TrendingUp}
            className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
          >
            START TRADING NOW
          </Button>
        </motion.div>
      </Container>
    </section>
  );
};

export default Markets;