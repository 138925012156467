import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { Users, DollarSign, TrendingUp, Shield } from 'lucide-react';
import SectionBanner from '../components/sections/SectionBanner';
import ReferralHero from '../components/referral/ReferralHero';
import ReferralBenefits from '../components/referral/ReferralBenefits';
import MoneyFlow from '../components/referral/flow/MoneyFlow';
import ReferralCommissions from '../components/referral/ReferralCommissions';
import ReferralFAQ from '../components/referral/ReferralFAQ';
import ReferralCTA from '../components/referral/ReferralCTA';

const Referral = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="EARN" 
        highlightText="$4 PER LOT" 
        ctaText="START EARNING"
        ctaLink="#hero"
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <DollarSign className="h-4 w-4 mr-2" />
              HIGH COMMISSIONS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <TrendingUp className="h-4 w-4 mr-2" />
              FAST PAYMENTS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              SECURE TRACKING
            </motion.div>
          </div>

          <ReferralHero />
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* Money Flow Section */}
        <SectionBanner 
          text="MONEY" 
          highlightText="FLOW" 
          ctaText="LEARN MORE"
          ctaLink="#flow"
        />

        <div id="flow">
          <Container className="py-24">
            <MoneyFlow />
          </Container>
        </div>

        {/* Commission Structure Section */}
        <SectionBanner 
          text="COMMISSION" 
          highlightText="STRUCTURE" 
          ctaText="VIEW RATES"
          ctaLink="#commissions"
        />

        <div id="commissions">
          <Container className="py-24">
            <ReferralCommissions />
          </Container>
        </div>

        {/* Benefits Section */}
        <SectionBanner 
          text="PARTNER" 
          highlightText="BENEFITS" 
          ctaText="EXPLORE BENEFITS"
          ctaLink="#benefits"
        />

        <div id="benefits">
          <Container className="py-24">
            <ReferralBenefits />
          </Container>
        </div>

        {/* FAQ Section */}
        <SectionBanner 
          text="COMMON" 
          highlightText="QUESTIONS" 
          ctaText="GET ANSWERS"
          ctaLink="#faq"
        />

        <div id="faq">
          <Container className="py-24">
            <ReferralFAQ />
          </Container>
        </div>

        {/* Final CTA Section */}
        <Container className="py-24">
          <ReferralCTA />
        </Container>

        {/* Final Banner */}
        <SectionBanner 
          text="START" 
          highlightText="EARNING" 
          ctaText="JOIN NOW"
          ctaLink="#join"
          className="mb-0"
        />
      </div>
    </div>
  );
};

export default Referral;