import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Instagram, MessageSquare, Mail, Phone, ArrowRight, Shield, Globe, Award, DollarSign, TrendingUp, LineChart, BarChart2 } from 'lucide-react';
import Container from '../ui/Container';
import PolicyModal from '../modals/PolicyModal';
import { useModal } from '../../hooks/useModal';
import { TRADING_URL } from '../../utils/constants';

const socialLinks = [
  {
    name: 'Instagram',
    icon: Instagram,
    href: 'https://www.instagram.com/hankotradex',
    label: '@hankotradex'
  },
  {
    name: 'Discord',
    icon: MessageSquare,
    href: 'https://discord.gg/EP394qGPDu',
    label: 'Join Discord'
  }
];

const quickLinks = [
  { name: 'MARKETS', href: '/#markets' },
  { name: 'TRADING', href: '/#trading' },
  { name: 'ACCOUNTS', href: '/#accounts' },
  { name: 'EDUCATION', href: '/guides' },
  { name: 'REFERRAL', href: '/referral' },
  { name: 'SPONSORS', href: '/sponsors' },
  { name: 'BONUS', href: '/bonus', badge: 'NEW' }
];

const Footer = () => {
  const policyModal = useModal();

  return (
    <footer className="bg-black pt-16 pb-8 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Base gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-black via-dark-light to-black opacity-50" />

        {/* Background Image */}
        <div className="absolute inset-0">
          <img 
            src="https://trustedsignals.com/wp-content/uploads/2025/01/Screenshot-2025-01-28-213604.png"
            alt="Trading Background"
            className="w-full h-full object-cover opacity-30"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-90" />
        </div>

        {/* Floating Trading Icons */}
        {[...Array(12)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -50, 0],
              x: [0, Math.random() * 50 - 25, 0],
              opacity: [0.1, 0.2, 0.1],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 5 + Math.random() * 5,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.2
            }}
          >
            {i % 4 === 0 && <LineChart className="h-8 w-8 text-brand-gold/10" />}
            {i % 4 === 1 && <BarChart2 className="h-8 w-8 text-brand-gold/10" />}
            {i % 4 === 2 && <TrendingUp className="h-8 w-8 text-brand-gold/10" />}
            {i % 4 === 3 && <DollarSign className="h-8 w-8 text-brand-gold/10" />}
          </motion.div>
        ))}

        {/* Glowing orbs */}
        <motion.div
          className="absolute top-1/4 -left-32 w-96 h-96 bg-brand-gold/5 rounded-full blur-[128px]"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.2, 0.1],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        <motion.div
          className="absolute bottom-1/4 -right-32 w-96 h-96 bg-brand-gold/5 rounded-full blur-[128px]"
          animate={{
            scale: [1, 1.3, 1],
            opacity: [0.1, 0.15, 0.1],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
            delay: 1
          }}
        />

        {/* Vignette effect */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,transparent_0%,rgba(0,0,0,0.8)_100%)]" />
      </div>

      <Container className="relative">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* Company Info */}
          <div>
            <Link to="/">
              <img 
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoTrade"
                className="h-8 mb-6"
              />
            </Link>
            <div className="flex items-center gap-2 mb-6">
              <Award className="h-5 w-5 text-brand-gold" />
              <span className="text-lg font-black text-white tracking-wide">
                #1 FOREX BROKER
              </span>
            </div>
            <div className="space-y-4">
              {socialLinks.map((social) => (
                <a
                  key={social.name}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 text-gray-400 hover:text-brand-gold transition-colors group"
                >
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-dark-light group-hover:bg-brand-gold/10 transition-colors">
                    <social.icon className="h-4 w-4" />
                  </div>
                  <span className="text-sm font-medium">{social.label}</span>
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-black text-white tracking-wider mb-6">QUICK LINKS</h3>
            <ul className="space-y-4">
              {quickLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.href}
                    className="flex items-center gap-2 text-gray-400 hover:text-brand-gold transition-colors group"
                  >
                    <ArrowRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                    {link.name}
                    {link.badge && (
                      <span className="px-2 py-0.5 text-xs font-black bg-brand-gold text-black rounded-full">
                        {link.badge}
                      </span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-black text-white tracking-wider mb-6">CONTACT US</h3>
            <ul className="space-y-4">
              <li>
                <a
                  href="mailto:support@hankotrade.com"
                  className="flex items-center gap-3 text-gray-400 hover:text-brand-gold transition-colors group"
                >
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-dark-light group-hover:bg-brand-gold/10 transition-colors">
                    <Mail className="h-4 w-4" />
                  </div>
                  <span>support@hankotrade.com</span>
                </a>
              </li>
              <li>
                <div className="flex items-center gap-3 text-gray-400 group">
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-dark-light group-hover:bg-brand-gold/10 transition-colors">
                    <Phone className="h-4 w-4" />
                  </div>
                  <span>24/7 Support</span>
                </div>
              </li>
            </ul>
          </div>

          {/* Trading */}
          <div>
            <h3 className="text-lg font-black text-white tracking-wider mb-6">TRADING</h3>
            <ul className="space-y-4">
              <li>
                <a
                  href={TRADING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-400 hover:text-brand-gold transition-colors group"
                >
                  <ArrowRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                  LIVE ACCOUNT
                </a>
              </li>
              <li>
                <a
                  href={TRADING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-400 hover:text-brand-gold transition-colors group"
                >
                  <ArrowRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                  DEMO ACCOUNT
                </a>
              </li>
              <li>
                <Link
                  to="/mtx4"
                  className="flex items-center gap-2 text-gray-400 hover:text-brand-gold transition-colors group"
                >
                  <ArrowRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                  DOWNLOAD MTX4
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-800/50 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} HankoTrade. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <button 
                onClick={policyModal.open}
                className="text-gray-400 hover:text-brand-gold transition-colors text-sm font-medium"
              >
                Withdrawal Policy
              </button>
              <button 
                onClick={policyModal.open}
                className="text-gray-400 hover:text-brand-gold transition-colors text-sm font-medium"
              >
                Deposit Policy
              </button>
            </div>
          </div>
          
          <div className="mt-6 text-center">
            <p className="text-gray-500 text-sm">
              Trading in financial instruments carries a high level of risk to your capital with the possibility of losing more than your initial investment. Trading is not suitable for everyone and requires knowledge and experience.
            </p>
          </div>
        </div>
      </Container>

      <PolicyModal 
        isOpen={policyModal.isOpen} 
        onClose={policyModal.close} 
      />
    </footer>
  );
};

export default Footer;