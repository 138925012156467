// Trading platform URLs
export const TRADING_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const DEMO_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const MAGNOFX_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const DOWNLOAD_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const SUPPORT_URL = 'mailto:support@magnofx.com';
export const LIVE_CHAT_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';

// Social media URLs
export const SOCIAL_URLS = {
  instagram: 'https://www.instagram.com/hankotradex',
  discord: 'https://discord.gg/EP394qGPDu'
} as const;

// Navigation URLs
export const NAV_URLS = {
  home: '/',
  markets: '/markets',
  trading: '/trading',
  about: '/about',
  contact: '/contact',
  mtx4: '/mtx4'
} as const;

// Default link for all trading-related actions
export const DEFAULT_TRADING_LINK = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';