import React, { Suspense } from 'react';
import Container from '../components/ui/Container';
import LoadingScreen from '../components/LoadingScreen';
import { motion } from 'framer-motion';
import { Monitor, ChevronRight, Shield, Zap } from 'lucide-react';
import Button from '../components/ui/Button';
import SectionBanner from '../components/sections/SectionBanner';
import { TRADING_URL } from '../utils/constants';

// Lazy load components
const TradingFlow = React.lazy(() => import('../components/platform/TradingFlow'));
const FeatureGrid = React.lazy(() => import('../components/platform/features/FeatureGrid'));
const TradingTools = React.lazy(() => import('../components/platform/TradingTools'));
const PlatformAdvantages = React.lazy(() => import('../components/platform/PlatformAdvantages'));
const PlatformCTA = React.lazy(() => import('../components/platform/PlatformCTA'));

const HankoX = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="TRADE" 
        highlightText="SMARTER" 
        ctaText="START NOW"
        ctaLink={TRADING_URL}
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Monitor className="h-4 w-4 mr-2" />
              ULTRA-LOW SPREADS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Zap className="h-4 w-4 mr-2" />
              INSTANT EXECUTION
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              SECURE TRADING
            </motion.div>
          </div>

          {/* Main Heading */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-5xl md:text-7xl lg:text-9xl font-black text-white mb-6 tracking-tighter text-center"
            style={{ fontWeight: 950 }}
          >
            {'UNLEASH YOUR'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: 0.3 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            <br />
            <span className="text-brand-gold">
              {'TRADING POTENTIAL'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.6 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-gray-400 text-lg max-w-3xl mx-auto mb-12 text-center"
          >
            Experience the next evolution in trading with HankoX. Our cutting-edge platform combines ultra-low spreads, 
            lightning-fast execution, and professional-grade tools to give you the ultimate trading advantage.
          </motion.p>

          {/* Platform Image */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="relative max-w-6xl mx-auto mb-12"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-2xl blur-xl opacity-75" />
            <div className="relative">
              <img
                src="https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-13-202518.png"
                alt="HankoX Trading Platform"
                className="w-full h-auto rounded-2xl shadow-2xl border border-brand-gold/20"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent rounded-2xl" />
            </div>
          </motion.div>

          {/* CTA Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="flex justify-center"
          >
            <Button
              href={TRADING_URL}
              size="lg"
              icon={ChevronRight}
              className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              START TRADING NOW
            </Button>
          </motion.div>
        </Container>
      </div>

      {/* Main Content with Banners */}
      <Suspense fallback={<LoadingScreen />}>
        <div className="space-y-0">
          {/* Trading Flow Section */}
          <SectionBanner 
            text="TRADE" 
            highlightText="ANYWHERE" 
            ctaText="EXPLORE PLATFORM"
            ctaLink={TRADING_URL}
          />

          <div id="trading-flow">
            <Container className="py-24">
              <TradingFlow />
            </Container>
          </div>

          {/* Features Section */}
          <SectionBanner 
            text="ADVANCED" 
            highlightText="FEATURES" 
            ctaText="VIEW FEATURES"
            ctaLink={TRADING_URL}
          />

          <div id="features">
            <Container className="py-24">
              <FeatureGrid />
            </Container>
          </div>

          {/* Tools Section */}
          <SectionBanner 
            text="PROFESSIONAL" 
            highlightText="TOOLS" 
            ctaText="EXPLORE TOOLS"
            ctaLink={TRADING_URL}
          />

          <div id="tools">
            <Container className="py-24">
              <TradingTools />
            </Container>
          </div>

          {/* Advantages Section */}
          <SectionBanner 
            text="PLATFORM" 
            highlightText="ADVANTAGES" 
            ctaText="LEARN MORE"
            ctaLink={TRADING_URL}
          />

          <div id="advantages">
            <Container className="py-24">
              <PlatformAdvantages />
            </Container>
          </div>

          {/* Final CTA */}
          <Container className="py-24">
            <PlatformCTA />
          </Container>

          {/* Final Banner */}
          <SectionBanner 
            text="START" 
            highlightText="TRADING" 
            ctaText="JOIN NOW"
            ctaLink={TRADING_URL}
            className="mb-0"
          />
        </div>
      </Suspense>
    </div>
  );
};

export default HankoX;