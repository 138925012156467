import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DollarSign, Gift, Shield } from 'lucide-react';
import { DEFAULT_TRADING_LINK } from '../../utils/constants';

const bannerMessages = [
  {
    icon: DollarSign,
    text: 'ULTRA-LOW SPREADS',
    link: DEFAULT_TRADING_LINK
  },
  {
    icon: Gift,
    text: '100% DEPOSIT BONUS',
    link: DEFAULT_TRADING_LINK
  },
  {
    icon: Shield,
    text: '24/7 SUPPORT',
    link: DEFAULT_TRADING_LINK
  }
];

const TopBanner = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % bannerMessages.length);
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 z-50 bg-brand-gold h-14">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Base gradient */}
        <div className="absolute inset-0 bg-gradient-to-r from-brand-gold via-brand-gold-light to-brand-gold opacity-90" />

        {/* Diagonal Lines Pattern */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-black transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>

        {/* Animated gradient overlay */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/5 to-white/0"
          animate={{
            x: ['-100%', '100%']
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </div>

      {/* Content */}
      <div className="relative z-10 h-full">
        <div className="container mx-auto px-4 h-full">
          <div className="flex items-center justify-center h-full gap-8">
            {/* Desktop View */}
            <div className="hidden md:flex items-center gap-8">
              {bannerMessages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => window.open(message.link, '_blank')}
                >
                  <message.icon className="h-4 w-4" />
                  <span className="text-sm font-bold">{message.text}</span>
                </motion.div>
              ))}
            </div>

            {/* Mobile View - Animated Single Message */}
            <div className="md:hidden h-full flex items-center justify-center overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut"
                  }}
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => window.open(bannerMessages[currentIndex].link, '_blank')}
                >
                  {React.createElement(bannerMessages[currentIndex].icon, {
                    className: "h-4 w-4"
                  })}
                  <span className="text-sm font-bold whitespace-nowrap">
                    {bannerMessages[currentIndex].text}
                  </span>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;