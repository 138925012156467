import React from 'react';
import Navbar from './Navbar';
import Footer from '../sections/Footer';
import TopBanner from '../sections/TopBanner';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-black text-white">
      <TopBanner />
      <div className="pt-14"> {/* Add padding-top to account for TopBanner height */}
        <Navbar />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;