import React from 'react';
import { motion } from 'framer-motion';
import { Mail, MessageSquare, Clock } from 'lucide-react';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const MTX4Support = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="lg:col-span-2 relative"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-lg blur-lg opacity-50" />
        
        <div className="relative bg-dark-light backdrop-blur-sm rounded-lg p-8 border border-brand-gold/20">
          <h3 className="text-2xl font-bold text-white mb-4">Need Assistance?</h3>
          <p className="text-gray-400 mb-6">
            Our dedicated support team is here to help you with any questions about MTX4 platform, 
            account management, or internal transfers between MagnoFX platforms.
          </p>
          
          <div className="flex flex-wrap gap-4">
            <Button
              variant="primary"
              icon={Mail}
              href="mailto:support@magnofx.com"
              className="flex-1 bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              support@magnofx.com
            </Button>
            <Button
              variant="secondary"
              icon={MessageSquare}
              href={TRADING_URL}
              className="flex-1 bg-dark hover:bg-dark-darker border border-brand-gold/20"
            >
              Live Chat Support
            </Button>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="relative"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-lg blur-lg opacity-50" />
        
        <div className="relative bg-dark-light backdrop-blur-sm rounded-lg p-8 border border-brand-gold/20">
          <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20 mb-6">
            <Clock className="h-6 w-6 text-brand-gold" />
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">24/7 Support</h3>
          <p className="text-gray-400 mb-6">
            Our support team is available around the clock to assist you with any platform-related inquiries.
          </p>
          <Button
            href={TRADING_URL}
            className="w-full justify-center bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
          >
            Contact Support
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

export default MTX4Support;