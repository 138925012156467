import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, Shield, Award, TrendingUp } from 'lucide-react';
import Button from '../../ui/Button';
import { GradientText } from '../../ui/GradientText';
import HeroLogo from '../elements/HeroLogo';

const headlines = [
  { top: 'ULTRA-LOW', bottom: 'SPREADS' },
  { top: 'ADVANCED', bottom: 'PLATFORM' },
  { top: 'GLOBAL', bottom: 'MARKETS' }
];

const HeroContent = () => {
  const [currentHeadline, setCurrentHeadline] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentHeadline((prev) => (prev + 1) % headlines.length);
    }, 4000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {/* Logo */}
      <HeroLogo />

      {/* Main Heading */}
      <motion.div className="max-w-7xl mx-auto text-center">
        {/* Reduced height and bottom margin */}
        <div className="h-[180px] sm:h-[220px] md:h-[300px] lg:h-[380px] relative mb-2">
          <AnimatePresence mode="wait">
            <motion.h1 
              key={currentHeadline}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              className="absolute inset-0 text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-black uppercase tracking-tighter"
              style={{ fontWeight: 950 }}
            >
              {headlines[currentHeadline].top.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.1 + (index * 0.05),
                    duration: 0.5,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:text-brand-gold transition-colors cursor-default"
                >
                  {char === '-' ? '\u2011' : char}
                </motion.span>
              ))}
              <br />
              <span className="text-brand-gold">
                {headlines[currentHeadline].bottom.split('').map((char, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      delay: 0.5 + (index * 0.05),
                      duration: 0.5,
                      type: "spring",
                      stiffness: 300
                    }}
                    className="inline-block hover:scale-110 transition-transform"
                  >
                    {char}
                  </motion.span>
                ))}
              </span>
            </motion.h1>
          </AnimatePresence>
        </div>

        {/* Feature Banners - Reduced top margin */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 mb-12">
          {[
            { icon: TrendingUp, text: 'Ultra-Low Spreads', highlight: 'FROM 0.0' },
            { icon: Shield, text: 'Advanced Platform', highlight: 'HANKOX' },
            { icon: Award, text: '24/7 Support', highlight: 'GLOBAL' }
          ].map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: index === 0 ? -50 : index === 2 ? 50 : 0, y: index === 1 ? 50 : 0 }}
              animate={{ opacity: 1, x: 0, y: 0 }}
              transition={{ delay: 0.8 + (index * 0.2) }}
              className="relative group overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-brand-blue/20 to-brand-blue-light/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              
              <div className="relative bg-dark-light backdrop-blur-sm p-6 border border-brand-blue/20 h-full">
                <div className="flex items-center gap-3 mb-2">
                  <feature.icon className="h-6 w-6 text-brand-blue" />
                  <span className="text-white font-medium">{feature.text}</span>
                </div>
                <div className="text-2xl font-black text-brand-blue tracking-wider">
                  {feature.highlight}
                </div>
              </div>

              {/* Diagonal Lines */}
              <div className="absolute inset-0 opacity-10">
                {[...Array(5)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute h-[200%] w-0.5 bg-brand-blue transform -rotate-45"
                    style={{
                      left: `${i * 25}%`,
                      top: '-50%'
                    }}
                  />
                ))}
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Buttons - Increased bottom margin */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.5 }}
          className="flex flex-col sm:flex-row items-center justify-center gap-4 px-4 mb-24"
        >
          <Button
            size="lg"
            icon={TrendingUp}
            className="w-full sm:w-auto min-w-[200px] bg-brand-blue hover:bg-brand-blue-dark text-white font-black tracking-wider"
          >
            START TRADING
          </Button>
          <Button
            variant="secondary"
            size="lg"
            className="w-full sm:w-auto min-w-[200px] bg-dark-light hover:bg-dark border border-brand-blue/20 font-bold tracking-wide"
          >
            DEMO ACCOUNT
          </Button>
        </motion.div>
      </motion.div>
    </>
  );
};

export default HeroContent;