import React from 'react';
import Hero from '../components/hero/Hero';
import Features from '../components/features/Features';
import Markets from '../components/sections/Markets';
import FXMarket from '../components/sections/FXMarket';
import TradingPlatform from '../components/sections/TradingPlatform';
import TradingTools from '../components/sections/TradingTools';
import SecuritySection from '../components/sections/SecuritySection';
import MobileAppSection from '../components/sections/mobile-app/MobileAppSection';
import CopyTradingSection from '../components/sections/copy-trading/CopyTradingSection';
import AccountTypes from '../components/sections/AccountTypes';
import EducationSection from '../components/sections/EducationSection';
import DiscordCommunity from '../components/sections/DiscordCommunity';
import SectionBanner from '../components/sections/SectionBanner';
import PlatformBanner from '../components/sections/PlatformBanner';
import PlatformFeaturesBanner from '../components/sections/PlatformFeaturesBanner';

const Home = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Hero />
      
      {/* Platform Banner and Features Section */}
      <PlatformBanner />
      <PlatformFeaturesBanner />
      
      <SectionBanner 
        text="TRADE GLOBAL" 
        highlightText="MARKETS" 
        ctaText="Explore Markets"
        ctaLink="#markets"
      />
      <section id="markets">
        <Markets />
        <FXMarket />
      </section>

      <SectionBanner 
        text="PROFESSIONAL" 
        highlightText="PLATFORM" 
        ctaText="Learn More"
        ctaLink="#trading"
      />
      <section id="trading">
        <TradingPlatform />
        <TradingTools />
        <SecuritySection />
      </section>

      <SectionBanner 
        text="COPY" 
        highlightText="TRADERS" 
        ctaText="Start Copy Trading"
      />
      <CopyTradingSection />

      <SectionBanner 
        text="TRADE" 
        highlightText="ANYWHERE" 
        ctaText="Get Mobile App"
      />
      <MobileAppSection />

      <SectionBanner 
        text="ACCOUNT" 
        highlightText="TYPES" 
        ctaText="Compare Accounts"
        ctaLink="#accounts"
      />
      <section id="accounts">
        <AccountTypes />
      </section>

      <SectionBanner 
        text="TRADING" 
        highlightText="EDUCATION" 
        ctaText="Start Learning"
        ctaLink="#education"
      />
      <section id="education">
        <EducationSection />
      </section>

      <SectionBanner 
        text="JOIN OUR" 
        highlightText="COMMUNITY" 
        ctaText="Join Discord"
      />
      <DiscordCommunity />
    </div>
  );
};

export default Home;