import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, DollarSign, Shield, Zap, Clock, LineChart } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import CurrencyPairsTable from './fx-market/CurrencyPairsTable';

const features = [
  {
    icon: DollarSign,
    title: '50+ Currency Pairs',
    description: 'Trade major, minor, and exotic currency pairs',
    iconColor: 'text-green-400',
    gradient: 'from-green-500/20 to-emerald-500/20'
  },
  {
    icon: TrendingUp,
    title: 'Competitive Spreads',
    description: 'Trade with spreads as low as 0.0 pips',
    iconColor: 'text-blue-400',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  },
  {
    icon: Clock,
    title: '24/5 Trading',
    description: 'Access forex markets around the clock',
    iconColor: 'text-purple-400',
    gradient: 'from-purple-500/20 to-violet-500/20'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Ultra-low latency trade execution',
    iconColor: 'text-yellow-400',
    gradient: 'from-yellow-500/20 to-amber-500/20'
  },
  {
    icon: LineChart,
    title: 'Advanced Tools',
    description: 'Professional charting and analysis tools',
    iconColor: 'text-orange-400',
    gradient: 'from-orange-500/20 to-red-500/20'
  },
  {
    icon: Shield,
    title: 'Secure Trading',
    description: 'Protected by bank-grade security',
    iconColor: 'text-indigo-400',
    gradient: 'from-indigo-500/20 to-blue-500/20'
  }
];

const popularPairs = [
  { pair: 'EUR/USD', spread: 0.0 },
  { pair: 'GBP/USD', spread: 0.1 },
  { pair: 'USD/JPY', spread: 0.1 },
  { pair: 'USD/CHF', spread: 0.2 },
  { pair: 'AUD/USD', spread: 0.2 },
  { pair: 'USD/CAD', spread: 0.3 },
  { pair: 'NZD/USD', spread: 0.3 }
];

const FXMarket = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-blue transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20 mb-6"
          >
            <TrendingUp className="h-4 w-4 mr-2" />
            Forex Trading • Multiple Markets
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-5xl md:text-6xl font-black text-white mb-6 tracking-tighter"
          >
            TRADE FOREX WITH{' '}
            <span className="text-brand-gold">COMPETITIVE SPREADS</span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Access the world's largest financial market with ultra-competitive spreads and advanced trading tools
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 + index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Card Background */}
              <motion.div
                className={`absolute -inset-px rounded-xl bg-gradient-to-r ${feature.gradient} blur-xl opacity-50 group-hover:opacity-100`}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 0.7, 0.5],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              {/* Card Content */}
              <div className="relative bg-dark-light backdrop-blur-sm rounded-xl p-8 border border-gray-800/50 h-full">
                <div className={`flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br ${feature.gradient} mb-6`}>
                  <feature.icon className={`h-6 w-6 ${feature.iconColor}`} />
                </div>
                
                <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                  {feature.description}
                </p>

                {/* Animated border */}
                <motion.div
                  className={`absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-transparent ${feature.gradient} to-transparent w-full`}
                  animate={{
                    scaleX: [0, 1, 0],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                />
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="relative group mb-16"
        >
          <div className="absolute -inset-px rounded-xl bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 blur-xl opacity-50 group-hover:opacity-100" />
          <div className="relative bg-dark-light backdrop-blur-sm rounded-xl p-8 border border-brand-gold/20">
            <h3 className="text-2xl font-bold text-white mb-6">Popular Currency Pairs</h3>
            <CurrencyPairsTable pairs={popularPairs} />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
          className="text-center"
        >
          <Button 
            size="lg"
            icon={TrendingUp}
            className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
          >
            START TRADING FOREX
          </Button>
        </motion.div>
      </Container>
    </section>
  );
};

export default FXMarket;