import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import MTX4 from './pages/MTX4';
import Guides from './pages/Guides';
import Referral from './pages/Referral';
import HankoX from './pages/HankoX';
import ForexBasics from './pages/ForexBasics';
import Bonus from './pages/Bonus';
import Sponsors from './pages/Sponsors';
import TradeWithPat from './pages/TradeWithPat';
import Logo from './pages/Logo'; // Import the new Logo page
import LoadingScreen from './components/LoadingScreen';

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <>
      {isLoading && <LoadingScreen onLoadingComplete={() => setIsLoading(false)} />}
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mtx4" element={<MTX4 />} />
            <Route path="/guides" element={<Guides />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/hankox" element={<HankoX />} />
            <Route path="/forex-basics" element={<ForexBasics />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/tradewithpat" element={<TradeWithPat />} />
            <Route path="/logo" element={<Logo />} /> {/* Add the new Logo route */}
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;