import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, LineChart, BarChart2, CandlestickChart, DollarSign } from 'lucide-react';
import { cn } from '../../utils/cn';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

interface SectionBannerProps {
  text: string;
  highlightText?: string;
  ctaText?: string;
  ctaLink?: string;
  className?: string;
}

const SectionBanner = ({ text, highlightText, ctaText, ctaLink = TRADING_URL, className }: SectionBannerProps) => {
  return (
    <div className={cn(
      "w-full bg-brand-gold relative overflow-hidden mt-20",
      className
    )}>
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Base gradient */}
        <div className="absolute inset-0 bg-gradient-to-r from-brand-gold via-brand-gold-light to-brand-gold opacity-90" />

        {/* Floating Trading Icons */}
        {[...Array(12)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -30, 0],
              x: [0, Math.random() * 30 - 15, 0],
              opacity: [0.1, 0.3, 0.1],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 4 + Math.random() * 4,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.2
            }}
          >
            {i % 4 === 0 && <LineChart className="h-8 w-8 text-black/20" />}
            {i % 4 === 1 && <BarChart2 className="h-8 w-8 text-black/20" />}
            {i % 4 === 2 && <CandlestickChart className="h-8 w-8 text-black/20" />}
            {i % 4 === 3 && <DollarSign className="h-8 w-8 text-black/20" />}
          </motion.div>
        ))}

        {/* Glowing orbs */}
        <motion.div
          className="absolute top-1/4 -left-32 w-96 h-96 bg-white/10 rounded-full blur-[128px]"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.2, 0.1],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        <motion.div
          className="absolute bottom-1/4 -right-32 w-96 h-96 bg-white/10 rounded-full blur-[128px]"
          animate={{
            scale: [1, 1.3, 1],
            opacity: [0.1, 0.15, 0.1],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
            delay: 1
          }}
        />
      </div>

      {/* Content Container */}
      <div className="relative z-10 py-12">
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="container mx-auto px-4 flex flex-col items-center justify-center gap-8 text-center"
        >
          {/* Text Content */}
          <motion.h2 
            className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl font-black text-black uppercase tracking-tighter flex flex-wrap justify-center items-baseline"
            style={{ fontWeight: 950 }}
          >
            {text.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ 
                  delay: 0.1 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            {highlightText && (
              <motion.span
                className="text-white ml-0 sm:ml-4 mt-2 sm:mt-0 drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)] block sm:inline"
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5, type: "spring", stiffness: 300 }}
              >
                {highlightText}
              </motion.span>
            )}
          </motion.h2>

          {/* CTA Button */}
          {ctaText && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6, type: "spring" }}
              className="w-full sm:w-auto"
            >
              <Button
                href={TRADING_URL} // Always use TRADING_URL for external links
                size="lg"
                icon={ArrowRight}
                className="w-full sm:w-auto bg-black text-white hover:bg-black/80 min-w-[200px] whitespace-nowrap group shadow-xl font-black tracking-wider"
              >
                <motion.span
                  className="inline-block"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  {ctaText}
                </motion.span>
              </Button>
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default SectionBanner;