import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, BarChart2, CandlestickChart, DollarSign, ChevronRight } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const PlatformBanner = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(247,167,37,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(247,167,37,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Floating Trading Icons */}
        {[...Array(12)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -50, 0],
              x: [0, Math.random() * 50 - 25, 0],
              opacity: [0.2, 0.4, 0.2],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 5 + Math.random() * 5,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.2
            }}
          >
            {i % 4 === 0 && <LineChart className="h-8 w-8 text-brand-gold/20" />}
            {i % 4 === 1 && <BarChart2 className="h-8 w-8 text-brand-gold/20" />}
            {i % 4 === 2 && <CandlestickChart className="h-8 w-8 text-brand-gold/20" />}
            {i % 4 === 3 && <DollarSign className="h-8 w-8 text-brand-gold/20" />}
          </motion.div>
        ))}
      </div>

      <Container className="relative">
        <div className="text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-7xl md:text-8xl lg:text-9xl font-black text-white mb-6 tracking-tighter"
            style={{ fontWeight: 950 }}
          >
            {'EXPERIENCE THE'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: 0.3 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            <br />
            <span className="text-brand-gold">
              {'NEXT EVOLUTION'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.6 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </span>
            <br />
            <span className="text-white">
              {'IN TRADING'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.9 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2 }}
            className="text-gray-400 text-xl max-w-4xl mx-auto mb-12"
          >
            Discover a new era of trading with our advanced platform, featuring ultra-low spreads, 
            lightning-fast execution, and professional-grade tools designed for traders of all levels.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.4 }}
            className="flex justify-center"
          >
            <Button
              href={TRADING_URL}
              size="lg"
              icon={ChevronRight}
              className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              START TRADING NOW
            </Button>
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default PlatformBanner;