import React from 'react';
import { motion } from 'framer-motion';
import { Download } from 'lucide-react';
import { GradientText } from '../ui/GradientText';
import StepCard from './steps/StepCard';
import { steps } from './steps/stepsData';

const MTX4Steps = () => {
  return (
    <div className="py-24 border-t border-gray-800/50">
      <div className="text-center mb-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20 mb-6"
        >
          <Download className="h-4 w-4 mr-2" />
          Get Started with MTX4
        </motion.div>

        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-5xl md:text-7xl lg:text-8xl font-black text-white mb-6 tracking-tighter"
        >
          {'UNLEASH YOUR'.split('').map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                delay: 0.1 + (index * 0.03),
                duration: 0.4,
                type: "spring",
                stiffness: 300
              }}
              className="inline-block hover:scale-110 transition-transform cursor-default"
            >
              {char === ' ' ? '\u00A0' : char}
            </motion.span>
          ))}
          <br />
          <span className="text-brand-gold">
            {'TRADING POTENTIAL'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: 0.4 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
          </span>
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Follow these simple steps to begin your journey with our enhanced MTX4 platform. 
          Start trading with professional-grade tools and features today.
        </motion.p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {steps.map((step, index) => (
          <StepCard key={index} {...step} index={index} />
        ))}
      </div>
    </div>
  );
};

export default MTX4Steps;