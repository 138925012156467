import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, BarChart2, CandlestickChart, DollarSign } from 'lucide-react';
import { cn } from '../utils/cn';

interface LoadingScreenProps {
  onLoadingComplete?: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ onLoadingComplete }) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onLoadingComplete?.();
    }, 2000);

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 20);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [onLoadingComplete]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-[9999] bg-black flex items-center justify-center overflow-hidden"
      >
        {/* Background Effects */}
        <div className="absolute inset-0">
          {/* Base gradient with blue and yellow */}
          <div className="absolute inset-0 bg-gradient-to-b from-black via-[#1a1a3a] to-black" />
          
          {/* Background Image */}
          <div className="absolute inset-0">
            <img 
              src="https://trustedsignals.com/wp-content/uploads/2025/01/Screenshot-2025-01-28-213604.png"
              alt="Trading Background"
              className="w-full h-full object-cover opacity-30"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-90" />
          </div>

          {/* Grid Pattern */}
          <motion.div 
            className="absolute inset-0 bg-[linear-gradient(rgba(247,167,37,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(247,167,37,0.05)_1px,transparent_1px)] bg-[size:32px_32px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 1.5 }}
          />

          {/* Trading Chart Background */}
          <div className="absolute inset-0 opacity-20">
            {[...Array(10)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute left-0 right-0 h-px bg-gradient-to-r from-blue-500/20 via-yellow-500/20 to-blue-500/20"
                style={{ top: `${10 + i * 8}%` }}
                animate={{
                  opacity: [0.2, 0.5, 0.2],
                  scaleY: [1, 1.5, 1],
                }}
                transition={{
                  duration: 2 + i,
                  repeat: Infinity,
                  ease: "easeInOut",
                  delay: i * 0.2
                }}
              />
            ))}
          </div>

          {/* Enhanced glowing orbs with blue and yellow */}
          <motion.div
            className="absolute top-1/4 -left-32 w-96 h-96 bg-gradient-to-r from-blue-500/20 to-yellow-500/20 rounded-full blur-[128px]"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear"
            }}
          />
          <motion.div
            className="absolute bottom-1/4 -right-32 w-96 h-96 bg-gradient-to-r from-yellow-500/20 to-blue-500/20 rounded-full blur-[128px]"
            animate={{
              scale: [1, 1.3, 1],
              opacity: [0.2, 0.4, 0.2],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
              delay: 1
            }}
          />

          {/* Additional glow in the center */}
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-yellow-500/5 to-blue-500/5 blur-3xl"
            animate={{
              opacity: [0.2, 0.4, 0.2],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />

          {/* Vignette effect */}
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,transparent_0%,rgba(0,0,0,0.8)_100%)]" />
        </div>

        {/* Main Content */}
        <div className="relative flex flex-col items-center px-4">
          {/* Logo Container */}
          <div className="relative mb-12">
            {/* Enhanced glow effect */}
            <motion.div
              className="absolute -inset-8 bg-gradient-to-r from-blue-500/30 via-yellow-500/30 to-blue-500/30 rounded-full blur-2xl"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.3, 0.5, 0.3],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            
            {/* Logo */}
            <motion.div
              className="relative bg-black/50 backdrop-blur-sm rounded-2xl p-6 border border-yellow-500/20"
              animate={{
                y: [0, -8, 0],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <motion.img
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoX"
                className="h-16 sm:h-20 w-auto"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.6,
                  ease: "easeOut"
                }}
              />
            </motion.div>

            {/* Orbiting Trading Icons */}
            {[...Array(8)].map((_, index) => (
              <motion.div
                key={index}
                className="absolute"
                style={{
                  top: '50%',
                  left: '50%',
                }}
                animate={{
                  rotate: [index * 45, index * 45 + 360],
                }}
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <motion.div
                  className="absolute -translate-x-1/2 -translate-y-1/2"
                  style={{
                    left: '80px',
                  }}
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.5, 1, 0.5],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                >
                  {index % 4 === 0 && <LineChart className="h-5 w-5 text-yellow-400" />}
                  {index % 4 === 1 && <BarChart2 className="h-5 w-5 text-blue-400" />}
                  {index % 4 === 2 && <CandlestickChart className="h-5 w-5 text-yellow-400" />}
                  {index % 4 === 3 && <DollarSign className="h-5 w-5 text-blue-400" />}
                </motion.div>
              </motion.div>
            ))}
          </div>

          {/* Progress Bar */}
          <div className="w-48 sm:w-64 mb-6">
            <div className="h-2 bg-gray-900 rounded-full overflow-hidden backdrop-blur-sm">
              <motion.div
                className="h-full bg-gradient-to-r from-blue-500 via-yellow-500 to-blue-500"
                style={{
                  width: `${progress}%`,
                  backgroundSize: '200% 100%',
                }}
                animate={{
                  backgroundPosition: ['0% 50%', '100% 50%'],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
            </div>
          </div>

          {/* Loading Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="text-gray-400 font-black text-lg tracking-wider uppercase"
          >
            Loading Professional Trading Platform
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoadingScreen;