import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { DollarSign, ChevronRight, Shield, Gift, Users } from 'lucide-react';
import Button from '../components/ui/Button';
import SectionBanner from '../components/sections/SectionBanner';
import { TRADING_URL } from '../utils/constants';

const Bonus = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="GET" 
        highlightText="100% BONUS" 
        ctaText="CLAIM NOW"
        ctaLink={TRADING_URL}
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="bonus" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Gift className="h-4 w-4 mr-2" />
              SPECIAL OFFER
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <DollarSign className="h-4 w-4 mr-2" />
              DOUBLE YOUR DEPOSIT
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              LIMITED TIME
            </motion.div>
          </div>

          {/* Main Heading */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-5xl md:text-7xl lg:text-9xl font-black text-white mb-6 tracking-tighter text-center"
            style={{ fontWeight: 950 }}
          >
            {'DOUBLE YOUR'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: 0.3 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            <br />
            <span className="text-brand-gold">
              {'TRADING POWER'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.6 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-gray-400 text-lg max-w-3xl mx-auto mb-12 text-center"
          >
            Double your trading power! Deposit $100 and trade with $200. 
            Limited time offer for all traders!
          </motion.p>

          {/* Bonus Image */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="relative max-w-lg mx-auto mb-12"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-2xl blur-xl opacity-75" />
            <div className="relative rounded-2xl overflow-hidden">
              <img
                src="https://trustedsignals.com/wp-content/uploads/2025/01/Screenshot-2025-01-28-214521.png"
                alt="100% Bonus Offer"
                className="w-full h-auto rounded-2xl shadow-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
            </div>
          </motion.div>

          {/* CTA Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="flex justify-center"
          >
            <Button
              href={TRADING_URL}
              size="lg"
              icon={ChevronRight}
              className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              CLAIM YOUR BONUS NOW
            </Button>
          </motion.div>
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* Features Section */}
        <SectionBanner 
          text="BONUS" 
          highlightText="FEATURES" 
          ctaText="LEARN MORE"
          ctaLink={TRADING_URL}
        />

        <div id="features">
          <Container className="py-24">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: Gift,
                  title: 'Trading Bonus',
                  description: 'Get up to $25,000 in bonus trading capital'
                },
                {
                  icon: Shield,
                  title: 'Secure Trading',
                  description: 'Your funds are protected with bank-grade security'
                },
                {
                  icon: Users,
                  title: 'For Everyone',
                  description: 'Perfect for both manual and EA traders'
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="group relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-lg blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
                  <div className="relative bg-dark-light backdrop-blur-sm rounded-lg p-8 border border-brand-gold/20">
                    <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20 mb-6">
                      <feature.icon className="h-6 w-6 text-brand-gold" />
                    </div>
                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </Container>
        </div>

        {/* How It Works Section */}
        <SectionBanner 
          text="HOW IT" 
          highlightText="WORKS" 
          ctaText="GET STARTED"
          ctaLink={TRADING_URL}
        />

        <div id="how-it-works">
          <Container className="py-24">
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-lg blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
              <div className="relative bg-dark-light backdrop-blur-sm rounded-lg p-8 border border-brand-gold/20">
                <h2 className="text-2xl font-bold text-white mb-6">How to Get Your Bonus</h2>
                <div className="space-y-4">
                  <div className="flex items-center gap-3">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand-gold/20 flex items-center justify-center text-brand-gold font-bold">1</div>
                    <div>
                      <h3 className="text-white font-medium">Sign Up & Deposit</h3>
                      <p className="text-gray-400">Create an account and make a deposit between $100 and $25,000</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand-gold/20 flex items-center justify-center text-brand-gold font-bold">2</div>
                    <div>
                      <h3 className="text-white font-medium">Get Your Bonus</h3>
                      <p className="text-gray-400">Receive your 100% bonus instantly</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand-gold/20 flex items-center justify-center text-brand-gold font-bold">3</div>
                    <div>
                      <h3 className="text-white font-medium">Start Trading</h3>
                      <p className="text-gray-400">Begin trading with your enhanced capital</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* Final CTA Banner */}
        <SectionBanner 
          text="CLAIM" 
          highlightText="NOW" 
          ctaText="GET BONUS"
          ctaLink={TRADING_URL}
          className="mb-0"
        />
      </div>
    </div>
  );
};

export default Bonus;