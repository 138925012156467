import React from 'react';
import { motion } from 'framer-motion';
import { Download, Globe } from 'lucide-react';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const MTX4Hero = () => {
  return (
    <div className="text-center max-w-5xl mx-auto px-4">
      {/* Main Content */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
        className="flex flex-col sm:flex-row items-center justify-center gap-4"
      >
        <Button
          href={TRADING_URL}
          size="lg"
          icon={Download}
          className="w-full sm:w-auto min-w-[200px] bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
        >
          DOWNLOAD MTX4
        </Button>
        <Button
          href={TRADING_URL}
          variant="secondary"
          size="lg"
          icon={Globe}
          className="w-full sm:w-auto min-w-[200px] bg-dark-light hover:bg-dark border border-brand-gold/20 font-bold tracking-wide"
        >
          WEB PLATFORM
        </Button>
      </motion.div>
    </div>
  );
};

export default MTX4Hero;