import React from 'react';
import { motion } from 'framer-motion';
import { Award } from 'lucide-react';

const HeroLogo = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="mb-8"
    >
      {/* Logo */}
      <img 
        src="https://hankotrade.com/static/img/logo.f92ae11.png"
        alt="HankoX"
        className="h-12 mx-auto mb-4"
      />

      {/* #1 Broker Text */}
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="flex items-center justify-center gap-2"
      >
        <Award className="h-5 w-5 text-brand-gold" />
        <div className="flex items-baseline gap-2">
          <span className="text-2xl font-black text-brand-gold">#1</span>
          <span className="text-lg font-bold text-white tracking-wide">
            FOREX BROKER
          </span>
        </div>
        <Award className="h-5 w-5 text-brand-gold" />
      </motion.div>
    </motion.div>
  );
};

export default HeroLogo;