import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, BarChart2, PieChart, ChevronRight } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';

const tools = [
  {
    icon: LineChart,
    title: 'Technical Analysis',
    description: 'Advanced charting tools with over 100 technical indicators and drawing tools',
    iconColor: 'text-green-400',
    gradient: 'from-green-500/20 to-emerald-500/20',
    borderGlow: 'group-hover:border-green-500/50'
  },
  {
    icon: BarChart2,
    title: 'Economic Calendar',
    description: 'Real-time economic events and market-moving indicators',
    iconColor: 'text-blue-400',
    gradient: 'from-blue-500/20 to-cyan-500/20',
    borderGlow: 'group-hover:border-blue-500/50'
  },
  {
    icon: PieChart,
    title: 'Risk Management',
    description: 'Sophisticated tools for managing your trading risk and portfolio',
    iconColor: 'text-purple-400',
    gradient: 'from-purple-500/20 to-violet-500/20',
    borderGlow: 'group-hover:border-purple-500/50'
  }
];

const TradingTools = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-gold transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20 mb-6"
          >
            <LineChart className="h-4 w-4 mr-2" />
            Professional Trading Tools
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-5xl md:text-6xl font-black text-white mb-6 tracking-tighter"
          >
            EVERYTHING YOU NEED FOR{' '}
            <span className="text-brand-gold">SUCCESSFUL TRADING</span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Access professional-grade trading tools designed to enhance your trading performance
          </motion.p>
        </div>

        {/* Tools Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {tools.map((tool, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 + index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Card Background */}
              <motion.div
                className={`absolute -inset-px rounded-xl bg-gradient-to-r ${tool.gradient} blur-xl opacity-50 group-hover:opacity-100`}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 0.7, 0.5],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              {/* Card Content */}
              <div className={`relative bg-dark-light backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 ${tool.borderGlow} h-full`}>
                {/* Icon with glow effect */}
                <div className="relative mb-6">
                  <motion.div
                    className={`absolute -inset-4 rounded-full bg-gradient-to-r ${tool.gradient} blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                  />
                  <div className={`relative flex items-center justify-center w-14 h-14 rounded-lg bg-gradient-to-br ${tool.gradient}`}>
                    <tool.icon className={`h-7 w-7 ${tool.iconColor}`} />
                  </div>
                </div>

                <h3 className="text-xl font-bold text-white mb-2">{tool.title}</h3>
                <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                  {tool.description}
                </p>

                {/* Animated border */}
                <motion.div
                  className={`absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-transparent ${tool.gradient} to-transparent w-full`}
                  animate={{
                    scaleX: [0, 1, 0],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                />
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
          className="text-center"
        >
          <Button
            size="lg"
            icon={ChevronRight}
            className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
          >
            EXPLORE TOOLS
          </Button>
        </motion.div>
      </Container>
    </section>
  );
};

export default TradingTools;