import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { BookOpen, ChevronRight, TrendingUp, BarChart2, LineChart, DollarSign, Shield } from 'lucide-react';
import Button from '../components/ui/Button';
import SectionBanner from '../components/sections/SectionBanner';
import ForexBasics from '../components/education/forex/ForexBasics';
import OrderTypes from '../components/education/forex/OrderTypes';
import SpreadExplanation from '../components/education/forex/SpreadExplanation';
import CurrencyPairs from '../components/education/forex/CurrencyPairs';
import PendingOrders from '../components/education/forex/PendingOrders';
import StopLossTakeProfit from '../components/education/forex/StopLossTakeProfit';

const ForexBasicsPage = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="LEARN" 
        highlightText="FOREX TRADING" 
        ctaText="START LEARNING"
        ctaLink="#basics"
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <BookOpen className="h-4 w-4 mr-2" />
              COMPREHENSIVE GUIDE
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <TrendingUp className="h-4 w-4 mr-2" />
              PROFESSIONAL EDUCATION
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              EXPERT GUIDANCE
            </motion.div>
          </div>

          {/* Main Heading */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-5xl md:text-7xl lg:text-9xl font-black text-white mb-6 tracking-tighter text-center"
            style={{ fontWeight: 950 }}
          >
            {'MASTER THE'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: 0.3 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            <br />
            <span className="text-brand-gold">
              {'FOREX MARKET'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.6 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-gray-400 text-lg max-w-3xl mx-auto mb-12 text-center"
          >
            Learn the fundamentals of forex trading, from basic concepts to advanced strategies. 
            Master currency pairs, order types, and risk management techniques.
          </motion.p>

          {/* Quick Navigation */}
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-12">
            {[
              { icon: TrendingUp, label: 'Forex Basics', link: '#basics' },
              { icon: BarChart2, label: 'Order Types', link: '#order-types' },
              { icon: LineChart, label: 'Spreads', link: '#understanding-spreads' },
              { icon: DollarSign, label: 'Currency Pairs', link: '#currency-pairs' },
              { icon: TrendingUp, label: 'Pending Orders', link: '#pending-orders' },
              { icon: Shield, label: 'Risk Management', link: '#stop-loss-take-profit' }
            ].map((item, index) => (
              <motion.a
                key={index}
                href={item.link}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 + index * 0.1 }}
                className="group relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
                <div className="relative flex items-center gap-3 p-4 bg-dark-light backdrop-blur-sm rounded-lg border border-brand-gold/20 hover:border-brand-gold/50">
                  <item.icon className="h-5 w-5 text-brand-gold" />
                  <span className="text-white font-medium">{item.label}</span>
                  <ChevronRight className="h-4 w-4 text-gray-400 group-hover:text-white ml-auto transition-colors" />
                </div>
              </motion.a>
            ))}
          </div>

          {/* CTA Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="flex justify-center"
          >
            <Button
              size="lg"
              icon={ChevronRight}
              className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              START LEARNING NOW
            </Button>
          </motion.div>
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* Forex Basics Section */}
        <SectionBanner 
          text="FOREX" 
          highlightText="BASICS" 
          ctaText="LEARN MORE"
          ctaLink="#basics"
        />

        <div id="basics">
          <Container className="py-24">
            <ForexBasics />
          </Container>
        </div>

        {/* Order Types Section */}
        <SectionBanner 
          text="ORDER" 
          highlightText="TYPES" 
          ctaText="EXPLORE ORDERS"
          ctaLink="#order-types"
        />

        <div id="order-types">
          <Container className="py-24">
            <OrderTypes />
          </Container>
        </div>

        {/* Spreads Section */}
        <SectionBanner 
          text="UNDERSTANDING" 
          highlightText="SPREADS" 
          ctaText="LEARN MORE"
          ctaLink="#understanding-spreads"
        />

        <div id="understanding-spreads">
          <Container className="py-24">
            <SpreadExplanation />
          </Container>
        </div>

        {/* Currency Pairs Section */}
        <SectionBanner 
          text="CURRENCY" 
          highlightText="PAIRS" 
          ctaText="EXPLORE PAIRS"
          ctaLink="#currency-pairs"
        />

        <div id="currency-pairs">
          <Container className="py-24">
            <CurrencyPairs />
          </Container>
        </div>

        {/* Pending Orders Section */}
        <SectionBanner 
          text="PENDING" 
          highlightText="ORDERS" 
          ctaText="LEARN MORE"
          ctaLink="#pending-orders"
        />

        <div id="pending-orders">
          <Container className="py-24">
            <PendingOrders />
          </Container>
        </div>

        {/* Risk Management Section */}
        <SectionBanner 
          text="RISK" 
          highlightText="MANAGEMENT" 
          ctaText="MASTER RISK"
          ctaLink="#stop-loss-take-profit"
        />

        <div id="stop-loss-take-profit">
          <Container className="py-24">
            <StopLossTakeProfit />
          </Container>
        </div>

        {/* Final CTA Banner */}
        <SectionBanner 
          text="START" 
          highlightText="TRADING" 
          ctaText="OPEN ACCOUNT"
          ctaLink="#start"
          className="mb-0"
        />
      </div>
    </div>
  );
};

export default ForexBasicsPage;