import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { BookOpen, ChevronRight, Video, TrendingUp, Shield } from 'lucide-react';
import Button from '../components/ui/Button';
import SectionBanner from '../components/sections/SectionBanner';
import YouTubeSection from '../components/education/YouTubeSection';
import CourseList from '../components/education/CourseList';

const Guides = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="MASTER" 
        highlightText="TRADING" 
        ctaText="START LEARNING"
        ctaLink="#courses"
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <BookOpen className="h-4 w-4 mr-2" />
              PROFESSIONAL EDUCATION
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Video className="h-4 w-4 mr-2" />
              VIDEO TUTORIALS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              EXPERT GUIDANCE
            </motion.div>
          </div>

          {/* Main Heading */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-5xl md:text-7xl lg:text-9xl font-black text-white mb-6 tracking-tighter text-center"
            style={{ fontWeight: 950 }}
          >
            {'MASTER THE'.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: 0.3 + (index * 0.03),
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300
                }}
                className="inline-block hover:scale-110 transition-transform cursor-default"
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
            <br />
            <span className="text-brand-gold">
              {'ART OF TRADING'.split('').map((char, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: 0.6 + (index * 0.03),
                    duration: 0.4,
                    type: "spring",
                    stiffness: 300
                  }}
                  className="inline-block hover:scale-110 transition-transform cursor-default"
                >
                  {char === ' ' ? '\u00A0' : char}
                </motion.span>
              ))}
            </span>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-gray-400 text-lg max-w-3xl mx-auto mb-12 text-center"
          >
            Access professional-grade trading education with our comprehensive courses and tutorials. 
            Learn from industry experts and develop the skills you need for successful trading.
          </motion.p>

          {/* CTA Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="flex justify-center"
          >
            <Button
              size="lg"
              icon={ChevronRight}
              className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
            >
              START LEARNING NOW
            </Button>
          </motion.div>
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* YouTube Section Banner */}
        <SectionBanner 
          text="VIDEO" 
          highlightText="TUTORIALS" 
          ctaText="WATCH NOW"
          ctaLink="#tutorials"
        />

        <div id="tutorials">
          <Container className="py-24">
            <YouTubeSection />
          </Container>
        </div>

        {/* Courses Section Banner */}
        <SectionBanner 
          text="TRADING" 
          highlightText="COURSES" 
          ctaText="EXPLORE COURSES"
          ctaLink="#courses"
        />

        <div id="courses">
          <Container className="py-24">
            <CourseList />
          </Container>
        </div>

        {/* Final CTA Banner */}
        <SectionBanner 
          text="START" 
          highlightText="LEARNING" 
          ctaText="GET STARTED"
          ctaLink="#start"
          className="mb-0"
        />
      </div>
    </div>
  );
};

export default Guides;