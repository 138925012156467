import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, ChevronDown, BookOpen, TrendingUp, Monitor, Laptop, DollarSign, Gift } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import Container from '../ui/Container';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const navLinks = [
  { href: '/', label: 'MARKETS' },
  { href: '/#trading', label: 'TRADING' },
  {
    href: '#',
    label: 'PLATFORM',
    dropdown: [
      { href: '/hankox', label: 'HANKOX', icon: Monitor, badge: 'NEW' },
      { href: '/mtx4', label: 'MTX4', icon: Laptop, badge: 'NEW' }
    ]
  },
  {
    href: '#',
    label: 'EDUCATION',
    dropdown: [
      { href: '/guides', label: 'TRADING GUIDES', icon: BookOpen },
      { href: '/forex-basics', label: 'FOREX BASICS', icon: TrendingUp, badge: 'NEW' }
    ]
  },
  { href: '/referral', label: 'REFERRAL', badge: '$4/LOT' },
  { 
    href: '#',
    label: 'BONUS',
    dropdown: [
      { href: '/bonus', label: '100% DEPOSIT BONUS', icon: DollarSign, badge: 'NEW' },
    ]
  }
];

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState<string | null>(null);
  const location = useLocation();
  const navRef = React.useRef<HTMLDivElement>(null);

  // Close menu when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close menu on route change
  React.useEffect(() => {
    setIsOpen(false);
    setOpenDropdown(null);
  }, [location]);

  // Lock body scroll when menu is open
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleNavClick = (href: string) => {
    setIsOpen(false);
    setOpenDropdown(null);
    
    if (href.startsWith('/#')) {
      if (location.pathname !== '/') {
        window.location.href = href;
        return;
      }
      
      const element = document.querySelector(href.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const toggleDropdown = (label: string) => {
    setOpenDropdown(openDropdown === label ? null : label);
  };

  return (
    <nav className="fixed w-full bg-black/80 backdrop-blur-md z-40 top-14" ref={navRef}>
      <Container>
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 relative group">
              <motion.div
                className="absolute -inset-2 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-opacity"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0, 0.5, 0],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <div className="relative">
                <img 
                  src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                  alt="HankoTrade"
                  className="h-8"
                />
              </div>
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden md:block ml-10">
              <div className="flex items-baseline space-x-1">
                {navLinks.map((link) => (
                  <div key={link.label} className="relative group">
                    {link.dropdown ? (
                      <>
                        <button 
                          className="text-gray-300 hover:text-brand-gold px-3 py-2 rounded-md text-sm font-bold tracking-wider transition-colors flex items-center gap-1"
                          onClick={() => toggleDropdown(link.label)}
                        >
                          {link.label}
                          <ChevronDown className="h-4 w-4" />
                        </button>
                        <div className="absolute left-0 mt-1 w-64 rounded-lg shadow-lg bg-dark-light ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 border border-brand-gold/20">
                          <div className="py-1">
                            {link.dropdown.map((item) => (
                              <Link
                                key={item.label}
                                to={item.href}
                                className="flex items-center gap-3 px-4 py-3 text-sm text-gray-300 hover:text-brand-gold hover:bg-black/50 group relative overflow-hidden"
                              >
                                <item.icon className="h-5 w-5" />
                                <span className="font-medium tracking-wide">{item.label}</span>
                                {item.badge && (
                                  <span className="ml-auto px-2 py-0.5 text-xs font-black bg-brand-gold text-black rounded-full">
                                    {item.badge}
                                  </span>
                                )}
                                <motion.div
                                  className="absolute inset-0 bg-gradient-to-r from-transparent via-brand-gold/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000"
                                />
                              </Link>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Link
                        to={link.href.replace('/#', '/')}
                        onClick={(e) => {
                          if (link.href.startsWith('/#')) {
                            e.preventDefault();
                            handleNavClick(link.href);
                          }
                        }}
                        className="relative text-gray-300 hover:text-brand-gold px-3 py-2 rounded-md text-sm font-bold tracking-wider transition-colors group overflow-hidden"
                      >
                        {link.label}
                        {link.badge && (
                          <span className="absolute -top-1 -right-2 px-2 py-0.5 text-xs font-black bg-brand-gold text-black rounded-full">
                            {link.badge}
                          </span>
                        )}
                        <motion.div
                          className="absolute bottom-0 left-0 h-0.5 w-full bg-brand-gold transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left"
                        />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Desktop Sign In Button */}
          <div className="hidden md:flex items-center">
            <Button
              variant="ghost"
              icon={TrendingUp}
              href={TRADING_URL}
              className="bg-brand-blue/10 hover:bg-brand-blue/20 text-brand-blue font-bold tracking-wider border border-brand-blue/20"
            >
              SIGN IN
            </Button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-lg text-gray-400 hover:text-brand-gold hover:bg-gray-800/50 focus:outline-none transition-colors"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden overflow-hidden bg-dark-light backdrop-blur-lg rounded-b-2xl border-t border-brand-gold/20"
            >
              <div className="px-4 pt-2 pb-4 space-y-1">
                {navLinks.map((link) => (
                  <React.Fragment key={link.label}>
                    {link.dropdown ? (
                      <>
                        <button
                          onClick={() => toggleDropdown(link.label)}
                          className="w-full flex items-center justify-between px-3 py-2 text-base font-bold text-gray-300 hover:text-brand-gold tracking-wider"
                        >
                          {link.label}
                          <ChevronDown 
                            className={`h-4 w-4 transition-transform duration-200 ${
                              openDropdown === link.label ? 'rotate-180' : ''
                            }`} 
                          />
                        </button>
                        <AnimatePresence>
                          {openDropdown === link.label && (
                            <motion.div
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: 'auto' }}
                              exit={{ opacity: 0, height: 0 }}
                              className="pl-4 space-y-1"
                            >
                              {link.dropdown.map((item) => (
                                <Link
                                  key={item.label}
                                  to={item.href}
                                  className="flex items-center gap-3 px-4 py-2 text-sm text-gray-300 hover:text-brand-gold hover:bg-black/50 rounded-lg group relative overflow-hidden"
                                  onClick={() => setIsOpen(false)}
                                >
                                  <item.icon className="h-5 w-5" />
                                  <span className="font-medium tracking-wide">{item.label}</span>
                                  {item.badge && (
                                    <span className="ml-auto px-2 py-0.5 text-xs font-black bg-brand-gold text-black rounded-full">
                                      {item.badge}
                                    </span>
                                  )}
                                </Link>
                              ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </>
                    ) : (
                      <Link
                        to={link.href.replace('/#', '/')}
                        onClick={(e) => {
                          if (link.href.startsWith('/#')) {
                            e.preventDefault();
                            handleNavClick(link.href);
                          }
                        }}
                        className="block px-3 py-2 rounded-lg text-base font-bold text-gray-300 hover:text-brand-gold hover:bg-black/50 tracking-wider"
                      >
                        {link.label}
                        {link.badge && (
                          <span className="ml-2 px-2 py-0.5 text-xs font-black bg-brand-gold text-black rounded-full">
                            {link.badge}
                          </span>
                        )}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
                <div className="pt-4">
                  <Button
                    variant="ghost"
                    icon={TrendingUp}
                    href={TRADING_URL}
                    className="w-full justify-center bg-brand-blue/10 hover:bg-brand-blue/20 text-brand-blue font-bold tracking-wider border border-brand-blue/20"
                  >
                    SIGN IN
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </nav>
  );
};

export default Navbar;