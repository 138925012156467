import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Youtube, TrendingUp, LineChart, BarChart2, DollarSign } from 'lucide-react';

const Logo = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden flex items-center justify-center">
      {/* Background Effects */}
      <motion.div 
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        {/* Grid pattern */}
        <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.03)_1px,transparent_1px)] bg-[size:32px_32px]" />
        
        {/* Animated gradient overlay */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-blue-500/5"
          animate={{
            backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
          }}
          transition={{
            duration: 15,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </motion.div>

      {/* Main Content Container */}
      <div className="relative flex flex-col items-center justify-center gap-12">
        {/* Logo Container */}
        <motion.div
          className="relative"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            type: "spring",
            stiffness: 200
          }}
        >
          {/* Orbiting Trading Icons */}
          {[...Array(8)].map((_, index) => (
            <motion.div
              key={index}
              className="absolute"
              style={{
                width: '300px',
                height: '300px',
                top: '50%',
                left: '50%',
                transform: `rotate(${index * 45}deg)`,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 + index * 0.1 }}
            >
              <motion.div
                className="absolute"
                style={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
                animate={{
                  rotate: 360,
                }}
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  ease: "linear",
                }}
              >
                {index % 4 === 0 && <TrendingUp className="h-8 w-8 text-blue-400" />}
                {index % 4 === 1 && <LineChart className="h-8 w-8 text-blue-400" />}
                {index % 4 === 2 && <BarChart2 className="h-8 w-8 text-blue-400" />}
                {index % 4 === 3 && <DollarSign className="h-8 w-8 text-blue-400" />}
              </motion.div>
            </motion.div>
          ))}

          {/* Logo Glow Effect */}
          <motion.div
            className="absolute -inset-8 bg-gradient-to-r from-blue-500/30 via-purple-500/30 to-blue-500/30 rounded-full blur-3xl"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.6, 0.3],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />

          {/* Logo */}
          <motion.div
            className="relative bg-black/50 backdrop-blur-xl rounded-full p-12 border border-blue-500/20"
            animate={{
              boxShadow: [
                "0 0 20px rgba(59,130,246,0.3)",
                "0 0 40px rgba(59,130,246,0.6)",
                "0 0 20px rgba(59,130,246,0.3)"
              ]
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <motion.img
              src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
              alt="HankoX"
              className="w-48 h-48 object-contain"
              animate={{
                scale: [1, 1.05, 1],
                rotate: [0, 5, 0]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
          </motion.div>
        </motion.div>

        {/* Subscribe Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
        >
          <motion.button
            className="bg-red-600 hover:bg-red-700 text-white px-8 py-4 rounded-lg flex items-center gap-3 relative overflow-hidden"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {/* Button Glow */}
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-red-500/0 via-red-500/30 to-red-500/0"
              animate={{
                x: ['-100%', '100%']
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear"
              }}
            />

            {/* Button Content */}
            <Youtube className="h-6 w-6" />
            <span className="text-xl font-bold relative z-10">Subscribe Now</span>
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default Logo;