import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, Settings, Zap, Shield, ChevronRight } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const PlatformFeaturesBanner = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(247,167,37,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(247,167,37,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />
      </div>

      <Container className="relative">
        <div className="grid grid-cols-12 gap-8 mb-16">
          {/* Left Column - Now taking up 62% (7.5/12 columns) */}
          <div className="col-span-12 lg:col-span-7">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="relative bg-dark-light/50 backdrop-blur-sm rounded-xl p-12 border border-gray-800/50 h-full"
            >
              <h3 className="text-7xl font-black text-brand-gold mb-8 leading-tight">
                FULLY
                <br />
                CUSTOMISABLE
                <br />
                PLATFORM
              </h3>

              <p className="text-gray-400 text-xl mb-12 max-w-3xl">
                Design your perfect trading workspace. Customize layouts, add widgets, and save multiple configurations for different trading strategies.
              </p>

              <div className="relative mb-8">
                <motion.div
                  className="absolute -inset-4 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-2xl blur-xl opacity-75"
                  animate={{
                    opacity: [0.5, 0.8, 0.5],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
                <img
                  src="https://trustedsignals.com/wp-content/uploads/2025/02/Screenshot-2025-02-02-155859.png"
                  alt="HankoX Platform"
                  className="w-full h-auto rounded-xl shadow-2xl transform hover:scale-105 transition-transform duration-500"
                />
              </div>

              {/* New Advanced Charting Tools Section */}
              <div className="mt-12">
                <h3 className="text-7xl font-black text-brand-gold mb-8 leading-tight">
                  ADVANCED
                  <br />
                  CHARTING
                  <br />
                  TOOLS
                </h3>

                <p className="text-gray-400 text-xl mb-12 max-w-3xl">
                  Access professional-grade charting tools with over 100 technical indicators, drawing tools, and multiple timeframe analysis capabilities.
                </p>

                <div className="relative">
                  <motion.div
                    className="absolute -inset-4 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-2xl blur-xl opacity-75"
                    animate={{
                      opacity: [0.5, 0.8, 0.5],
                    }}
                    transition={{
                      duration: 4,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  />
                  <img
                    src="https://trustedsignals.com/wp-content/uploads/2025/02/Screenshot-2025-02-02-173638.png"
                    alt="Advanced Charting Tools"
                    className="w-full h-auto rounded-xl shadow-2xl transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
              </div>
            </motion.div>
          </div>

          {/* Right Column - Modified with new images */}
          <div className="col-span-12 lg:col-span-5">
            <div className="grid grid-cols-1 gap-8">
              {/* Top Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="relative bg-dark-light/50 backdrop-blur-sm rounded-xl p-8 border border-gray-800/50"
              >
                <h3 className="text-4xl font-black text-brand-gold mb-4 leading-tight">
                  PROFESSIONAL
                  <br />
                  TRADING TOOLS
                </h3>
                <p className="text-gray-400">
                  Execute trades with precision using our advanced order types, risk management tools, and real-time market data.
                </p>
              </motion.div>

              {/* Features Grid - Modified with images */}
              <div className="grid grid-cols-1 gap-4">
                {/* One-Click Trading with Image */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4 }}
                  className="relative bg-dark-light/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 hover:border-brand-gold/50 transition-colors"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20">
                      <Zap className="h-5 w-5 text-brand-gold" />
                    </div>
                    <h4 className="text-lg font-bold text-white">
                      One-Click Trading
                    </h4>
                  </div>
                  <div className="relative mt-4">
                    <motion.div
                      className="absolute -inset-2 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-xl blur-lg opacity-75"
                      animate={{
                        opacity: [0.5, 0.8, 0.5],
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <img
                      src="https://trustedsignals.com/wp-content/uploads/2025/02/Screenshot-2025-02-02-170032.png"
                      alt="One-Click Trading"
                      className="w-full h-auto rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </motion.div>

                {/* Advanced Orders with Image */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.5 }}
                  className="relative bg-dark-light/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 hover:border-brand-gold/50 transition-colors"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20">
                      <LineChart className="h-5 w-5 text-brand-gold" />
                    </div>
                    <h4 className="text-lg font-bold text-white">
                      Advanced Orders
                    </h4>
                  </div>
                  <div className="relative mt-4">
                    <motion.div
                      className="absolute -inset-2 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-xl blur-lg opacity-75"
                      animate={{
                        opacity: [0.5, 0.8, 0.5],
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <img
                      src="https://trustedsignals.com/wp-content/uploads/2025/02/Screenshot-2025-02-02-172821-1.png"
                      alt="Advanced Orders"
                      className="w-full h-auto rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </motion.div>

                {/* Risk Controls with Image */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.6 }}
                  className="relative bg-dark-light/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 hover:border-brand-gold/50 transition-colors"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20">
                      <Shield className="h-5 w-5 text-brand-gold" />
                    </div>
                    <h4 className="text-lg font-bold text-white">
                      Risk Controls
                    </h4>
                  </div>
                  <div className="relative mt-4">
                    <motion.div
                      className="absolute -inset-2 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-xl blur-lg opacity-75"
                      animate={{
                        opacity: [0.5, 0.8, 0.5],
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <img
                      src="https://trustedsignals.com/wp-content/uploads/2025/02/Screenshot-2025-02-02-171955.png"
                      alt="Risk Controls"
                      className="w-full h-auto rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </motion.div>

                {/* Custom Watchlist with Image */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.7 }}
                  className="relative bg-dark-light/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 hover:border-brand-gold/50 transition-colors"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20">
                      <Settings className="h-5 w-5 text-brand-gold" />
                    </div>
                    <h4 className="text-lg font-bold text-white">
                      Custom Watchlist
                    </h4>
                  </div>
                  <div className="relative mt-4">
                    <motion.div
                      className="absolute -inset-2 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 rounded-xl blur-lg opacity-75"
                      animate={{
                        opacity: [0.5, 0.8, 0.5],
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <img
                      src="https://trustedsignals.com/wp-content/uploads/2025/02/Screenshot-2025-02-02-173044.png"
                      alt="Custom Watchlist"
                      className="w-full h-auto rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.8 }}
          className="text-center"
        >
          <Button
            href={TRADING_URL}
            size="lg"
            icon={ChevronRight}
            className="bg-brand-gold hover:bg-brand-gold-dark text-black font-black tracking-wider"
          >
            EXPLORE PLATFORM
          </Button>
        </motion.div>
      </Container>
    </section>
  );
};

export default PlatformFeaturesBanner;