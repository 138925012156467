import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { Monitor, ChevronRight, Shield, Zap, Download, Globe } from 'lucide-react';
import Button from '../components/ui/Button';
import SectionBanner from '../components/sections/SectionBanner';
import MTX4Hero from '../components/mtx4/MTX4Hero';
import MTX4Steps from '../components/mtx4/MTX4Steps';
import MTX4Features from '../components/mtx4/MTX4Features';
import MTX4Support from '../components/mtx4/MTX4Support';
import MTX4Notification from '../components/mtx4/notification/MTX4Notification';

const MTX4 = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="PROFESSIONAL" 
        highlightText="TRADING PLATFORM" 
        ctaText="START NOW"
        ctaLink="#hero"
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Monitor className="h-4 w-4 mr-2" />
              ULTRA-LOW SPREADS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Zap className="h-4 w-4 mr-2" />
              INSTANT EXECUTION
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              SECURE TRADING
            </motion.div>
          </div>

          <MTX4Hero />
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* Steps Section Banner */}
        <SectionBanner 
          text="START" 
          highlightText="TRADING NOW" 
          ctaText="GET STARTED"
          ctaLink="#steps"
        />

        <div id="steps">
          <Container className="py-24">
            <MTX4Steps />
          </Container>
        </div>

        {/* Features Section Banner */}
        <SectionBanner 
          text="ADVANCED" 
          highlightText="PLATFORM FEATURES" 
          ctaText="EXPLORE FEATURES"
          ctaLink="#features"
        />

        <div id="features">
          <Container className="py-24">
            <MTX4Features />
          </Container>
        </div>

        {/* Notification Section Banner */}
        <SectionBanner 
          text="CUSTOM" 
          highlightText="MTX4 PLATFORM" 
          ctaText="LEARN MORE"
          ctaLink="#notification"
        />

        <div id="notification">
          <Container className="py-24">
            <MTX4Notification />
          </Container>
        </div>

        {/* Support Section Banner */}
        <SectionBanner 
          text="24/7" 
          highlightText="SUPPORT" 
          ctaText="GET HELP"
          ctaLink="#support"
        />

        <div id="support">
          <Container className="py-24">
            <MTX4Support />
          </Container>
        </div>

        {/* Final CTA Banner */}
        <SectionBanner 
          text="START" 
          highlightText="TRADING" 
          ctaText="DOWNLOAD MTX4"
          ctaLink="#download"
          className="mb-0"
        />
      </div>
    </div>
  );
};

export default MTX4;