import React from 'react';
import { motion } from 'framer-motion';
import { Info } from 'lucide-react';
import { cn } from '../../../utils/cn';
import MTX4NotificationContent from './MTX4NotificationContent';
import MTX4NotificationImage from './MTX4NotificationImage';

const MTX4Notification = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative mb-24"
    >
      {/* Background glow effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-brand-gold/20 via-brand-gold-light/20 to-brand-gold/20 rounded-xl blur-xl opacity-50" />
      
      {/* Card container */}
      <div className={cn(
        "relative bg-dark-light backdrop-blur-sm rounded-xl p-8",
        "border border-brand-gold/20"
      )}>
        {/* Badge */}
        <div className="mb-6">
          <div className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20">
            <Info className="h-4 w-4 mr-2" />
            Important Information
          </div>
        </div>
        
        {/* Content Grid */}
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          <MTX4NotificationContent />
          <MTX4NotificationImage />
        </div>
      </div>
    </motion.div>
  );
};

export default MTX4Notification;