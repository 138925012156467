import { UserPlus, ArrowRight, Wallet, Download } from 'lucide-react';
import { TRADING_URL } from '../../../utils/constants';

export const steps = [
  {
    icon: UserPlus,
    title: 'Create MTX4 Live Account',
    description: 'Visit magnofx.com to create your new MTX4 live account',
    buttonText: 'Create Account',
    href: TRADING_URL
  },
  {
    icon: ArrowRight,
    title: 'Complete Registration',
    description: "Complete your registration process on MagnoFX.com",
    buttonText: 'Visit MagnoFX',
    href: TRADING_URL
  },
  {
    icon: Wallet,
    title: 'Fund Your Account',
    description: 'Fund your trading account directly through MagnoFX.com platform',
    buttonText: 'Fund Account',
    href: TRADING_URL
  },
  {
    icon: Download,
    title: 'Download MTX4',
    description: 'Download and install the MTX4 platform for Windows desktop',
    buttonText: 'Download MTX4',
    href: TRADING_URL
  }
];