import React from 'react';
import Container from '../components/ui/Container';
import { motion } from 'framer-motion';
import { Users, Star, Shield, Gift, TrendingUp } from 'lucide-react';
import SectionBanner from '../components/sections/SectionBanner';
import HeroSection from '../components/tradewithpat/HeroSection';
import ProfileImage from '../components/tradewithpat/ProfileImage';
import StatsGrid from '../components/tradewithpat/StatsGrid';
import PlatformSection from '../components/tradewithpat/sections/PlatformSection';
import MarketsSection from '../components/tradewithpat/sections/MarketsSection';
import TestimonialsSection from '../components/tradewithpat/sections/TestimonialsSection';
import YouTubeSection from '../components/tradewithpat/YouTubeSection';
import StickyCTA from '../components/tradewithpat/StickyCTA';

const TradeWithPat = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Opening Banner */}
      <SectionBanner 
        text="TRADE WITH" 
        highlightText="PAT" 
        ctaText="START TRADING"
        ctaLink="#hero"
        className="mb-0"
      />

      {/* Hero Section */}
      <div id="hero" className="relative">
        <Container className="py-16">
          {/* Banners Container */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Star className="h-4 w-4 mr-2" />
              PROFESSIONAL TRADER
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <TrendingUp className="h-4 w-4 mr-2" />
              EXPERT SIGNALS
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center bg-brand-gold/10 text-brand-gold px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-gold/20"
            >
              <Shield className="h-4 w-4 mr-2" />
              VERIFIED TRADER
            </motion.div>
          </div>

          <HeroSection />
          <ProfileImage />
          <StatsGrid />
        </Container>
      </div>

      {/* Main Content with Banners */}
      <div className="space-y-0">
        {/* Platform Section */}
        <SectionBanner 
          text="TRADING" 
          highlightText="PLATFORM" 
          ctaText="EXPLORE PLATFORM"
          ctaLink="#platform"
        />

        <div id="platform">
          <Container className="py-24">
            <PlatformSection />
          </Container>
        </div>

        {/* Markets Section */}
        <SectionBanner 
          text="TRADE" 
          highlightText="MARKETS" 
          ctaText="VIEW MARKETS"
          ctaLink="#markets"
        />

        <div id="markets">
          <Container className="py-24">
            <MarketsSection />
          </Container>
        </div>

        {/* Testimonials Section */}
        <SectionBanner 
          text="TRADER" 
          highlightText="REVIEWS" 
          ctaText="READ MORE"
          ctaLink="#testimonials"
        />

        <div id="testimonials">
          <Container className="py-24">
            <TestimonialsSection />
          </Container>
        </div>

        {/* YouTube Section */}
        <SectionBanner 
          text="VIDEO" 
          highlightText="TUTORIALS" 
          ctaText="WATCH NOW"
          ctaLink="#tutorials"
        />

        <div id="tutorials">
          <Container className="py-24">
            <YouTubeSection />
          </Container>
        </div>

        {/* Final CTA Banner */}
        <SectionBanner 
          text="START" 
          highlightText="TRADING" 
          ctaText="JOIN NOW"
          ctaLink="#join"
          className="mb-0"
        />
      </div>

      <StickyCTA />
    </div>
  );
};

export default TradeWithPat;